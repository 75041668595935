/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import classnames from 'src/classnames';

import useTracking from 'components/Globals/Analytics';
import { useTrackingClickStyles } from 'components/Globals/TrackingTester';

import { usePropsDrillProvider } from 'utils/hooks/usePropsDrillProvider';
import Typography from '../Typography';
import classes from './SwitchV1.module.scss';

const SwitchV1 = ({
  leftLabel,
  rightLabel,
  onChange,
  isShow,
  isDisabled,
  specialPermission,
  source,
  styles,
  trackingData,
  skipTracking,
}) => {
  const { confirmSpecialPermissionWithSource } = usePropsDrillProvider();
  const [isVisibleValue, setIsVisibleValue] = useState(false);
  const trackingClasses = useTrackingClickStyles(trackingData, skipTracking);
  const track = useTracking();

  useEffect(() => {
    setIsVisibleValue(isShow);
  }, [isShow]);

  const onShow = () => {
    setIsVisibleValue(true);
    if (onChange) {
      onChange(true);
    }
  };

  const onHide = () => {
    setIsVisibleValue(false);
    if (onChange) {
      onChange(false);
    }
  };

  let onShowHandler = onShow;
  let onHideHandler = onHide;

  if (specialPermission) {
    if (!specialPermission?.permission) {
      onShowHandler = confirmSpecialPermissionWithSource(specialPermission?.permission, onShow, source);
      onHideHandler = confirmSpecialPermissionWithSource(specialPermission?.permission, onHide, source);
    }
  }

  const handleChange = e => {
    e.preventDefault();
    e.stopPropagation();

    if (isDisabled) {
      return;
    }

    if (isVisibleValue) {
      onHideHandler();
    } else {
      onShowHandler();
    }

    if (trackingData && Object.keys(trackingData).length) {
      track.click(trackingData);
    }
  };

  return (
    <div
      className={classnames(classes.switchContainer, trackingClasses, {
        [styles?.root]: !!styles?.root,
      })}
      onClick={handleChange}
    >
      <Typography
        size="12"
        {...(!isVisibleValue && { weight: 'medium' })}
        className={classnames(classes.leftLabel, {
          [styles?.leftLabel]: !!styles?.leftLabel,
        })}
      >
        {leftLabel}
      </Typography>
      <label className={classes.switch}>
        <input type="checkbox" checked={isVisibleValue} disabled={isDisabled} />
        <span
          className={classnames(classes.slider, classes.round, {
            [styles?.slider]: !!styles?.slider,
          })}
        ></span>
      </label>
      <Typography
        size="12"
        {...(isVisibleValue && { weight: 'medium' })}
        className={classnames(classes.rightLabel, {
          [classes.checkedLabel]: isVisibleValue,
          [styles?.rightLabel]: !!styles?.rightLabel,
        })}
      >
        {rightLabel}
      </Typography>
    </div>
  );
};

export default SwitchV1;
